.loading {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
}
.loadingText {
  font-size: 1.25rem;
  font-weight: 700;
}
.content {
  margin: 0 auto;
  max-width: 1200px;
  padding: 2rem 1rem;
  width: 100%;
}
.heading {
  color: var(--heading);
  font-size: 1.5rem;
  line-height: 1.4em;
  margin-bottom: 1rem;
}
.list {
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 6%);
  overflow: hidden;
}
.link {
  align-items: center;
  background: #fff;
  border-bottom: 2px solid var(--graySpecial);
  color: currentColor;
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  padding: 1.25rem 1rem;
}
