.loading {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
}
.loadingText {
  font-size: 1.25rem;
  font-weight: 700;
}
.navbar {
  background: #fff;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 6%);
  display: flex;
  flex-wrap: nowrap;
}
.navButton {
  appearance: none;
  background: transparent;
  border: 0;
  color: var(--text);
  display: block;
  flex: 1;
  font-family: "Ica Text Ny";
  font-weight: 700;
  height: 2.5rem;
  width: 100%;
}
.activeNavButton {
  composes: navButton;
  color: var(--redDark);

  position: relative;
}
.activeNavButton:after {
  /* box-shadow: 0 2px 0px 0 rgba(155, 6, 0, 1); */
  background: var(--redDark);
  bottom: -1px;
  content: "";
  display: block;
  height: 2px;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
}
.charts {
  background: transparent;
  border-radius: 100%;
  height: 200px;
  margin: 2rem auto;
  position: relative;
  width: 200px;
}
.chart {
  background: transparent;
  position: absolute;
  top: 0;
  width: 100%;
}
.chartSvg {
  display: block;
  max-width: 100%;
}
.circle {
  fill: none;
  stroke: currentColor;
  opacity: 0.15;
  stroke-width: 2.5;
}
.fill {
  stroke: currentColor;
  fill: none;
  stroke-width: 2.5;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}
.chart:nth-child(1) {
  color: #e3000b;
}
.chart:nth-child(2) {
  color: #009738;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 82%;
}
.chart:nth-child(2) .circle {
  stroke-width: 3;
}
.chart:nth-child(2) .fill {
  stroke-width: 2.9;
}
.chart:nth-child(3) {
  color: #0068a8;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 63%;
}
.chart:nth-child(3) .circle {
  stroke-width: 4;
}
.chart:nth-child(3) .fill {
  stroke-width: 3.9;
}
.links {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.link {
  color: currentColor;
  margin-bottom: 1rem;
}
.questionsButton {
  appearance: none;
  background: var(--bgCta);
  border: none;
  border-radius: 100px;
  box-shadow: 0;
  color: #fff;
  display: block;
  font-size: 14px;
  font-weight: 700;
  margin: 0 auto;
  max-width: 250px;
  padding: 0.75rem 1rem;
  text-align: center;
  text-decoration: none;
  width: 100%;
}
.status {
  padding: 1.5rem 1rem 1rem;
}
.statusHeading {
  color: var(--heading);
  font-family: "ICA Rubrik";
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 1rem;
}
.mainHeading {
  color: var(--heading);
  font-family: "ICA Rubrik";
  font-size: 1.25rem;
  font-weight: 700;
  text-align: center;
  margin-top: 50px;
}
.subHeading {
  color: var(--text);
  font-size: 14px;
  line-height: 1.4em;
  text-align: center;
  margin-top: 20px;
  padding: 0 1rem;
}
.subLessHeading {
  color: var(--text);
  font-size: 14px;
  line-height: 1.4em;
  text-align: center;
  margin-top: 10px;
  padding: 0 1rem;
}
.statusList {
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  margin-bottom: 1.5rem;
  overflow: hidden;
}
.statusItem {
  align-items: center;
  border-bottom: 1px solid var(--graySpecial);
  display: flex;
  flex-wrap: nowrap;
  font-weight: 700;
  justify-content: space-between;
  padding: 1rem;
}
.statusItem:last-child {
  border-bottom: 0;
}
.statusItem .name {
  color: #009738;
}
.statusItem:first-child .name {
  color: #e3000b;
}
.statusItem:last-child .name {
  color: #0068a8;
}
.goal {
  font-size: 12px;
  color: lightslategray;
  margin-top: 5px;
}
.goalright {
  font-size: 12px;
  color: lightslategray;
  margin-top: 5px;
  text-align: right;
}
.inspiration {
  padding: 0 0 2rem;
}
.inspirationText {
  font-size: 14px;
  line-height: 1.4em;
  text-align: center;
  margin: 0 auto 1rem;
  max-width: 280px;
}
.inspirationButton {
  background: var(--bgCtaSecondary);
  border-radius: 100px;
  color: var(--colorCtaSecondary);
  display: block;
  font-size: 14px;
  font-weight: 700;
  margin: 0 auto;
  max-width: 200px;
  text-align: center;
  text-decoration: none;
  padding: 0.75rem 2rem;
}
.endedHeading {
  composes: mainHeading;
  margin-top: 0;
}
.endedSubHeading {
  composes: subHeading;
  color: var(--heading);
  font-weight: 700;
}
.endedIcon {
  display: block;
  margin: 50px auto 0.5rem;
  max-width: 120px;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}
