.loading {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
}
.loadingText {
  font-size: 1.25rem;
  font-weight: 700;
}
.subHeader {
  padding: 0.75rem 0;
}
.backButton {
  align-items: center;
  appearance: none;
  background: #fff;
  border: 0;
  border-radius: 100px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  color: var(--red);
  display: inline-flex;
  font-size: 14px;
  font-weight: 700;
  padding: 1rem 1.5rem 1rem 1.25rem;
  text-decoration: none;
}
.backIcon {
  margin-right: 0.25rem;
  width: 1.25rem;
}
.form {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  margin: 0 auto;
  padding: 2rem 1.5rem;
}
.questionHeading {
  color: var(--red);
  font-family: 'ICA Rubrik';
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 20px;
}
.submitButton {
  appearance: none;
  background: var(--bgCta);
  border: 0;
  border-radius: 100px;
  box-shadow: none;
  color: #fff;
  cursor: pointer;
  display: block;
  font-family: 'Ica Text Ny';
  font-size: 1rem;
  font-weight: 700;
  height: 2.5rem;
  margin: 0 auto;
  max-width: 256px;
  width: 100%;
}
.charts {
  background: transparent;
  border-radius: 100%;
  height: 200px;
  margin: 2rem auto;
  position: relative;
  width: 200px;
}
.chart {
  background: transparent;
  position: absolute;
  top: 0;
  width: 100%;
}
.chartSvg {
  display: block;
  max-width: 100%;
}
.circle {
  fill: none;
  stroke: currentColor;
  opacity: 0.15;
  stroke-width: 2.5;
}
.fill {
  stroke: currentColor;
  fill: none;
  stroke-width: 2.5;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}
.chart:nth-child(1) {
  color: #e3000b;
}
.chart:nth-child(2) {
  color: #009738;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 82%;
}
.chart:nth-child(2) .circle {
  stroke-width: 3;
}
.chart:nth-child(2) .fill {
  stroke-width: 2.9;
}
.chart:nth-child(3) {
  color: #0068a8;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 63%;
}
.chart:nth-child(3) .circle {
  stroke-width: 4;
}
.chart:nth-child(3) .fill {
  stroke-width: 3.9;
}
.links {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.link {
  color: currentColor;
  margin-bottom: 1rem;
}
.invite {
  height: 100vh;
  padding: 0;
}
.inviteHeader {
  background: #888;
  height: 30vh;
}
.inviteContent {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  margin: 0 1rem 1rem;
  min-height: 68vh;
  padding: 1.5rem;
  transform: translateY(-2rem);
}
.inviteLabel {
  color: var(--red);
  display: block;
  margin-bottom: 1rem;
}
.inviteHeading {
  font-size: 2rem;
  font-weight: 900;
  line-height: 1.2em;
  margin-bottom: 1rem;
}
.inviteForm {
  margin-bottom: 1.5rem;
}
.inviteInput {
  appearance: none;
  background: #fff;
  border: 1px solid var(--grayLight);
  border-radius: 4px;
  box-shadow: 0;
  font-size: 1rem;
  height: 3rem;
  margin-bottom: 0.5rem;
  padding: 0 0.75rem;
  width: 100%;
}
.inviteButton {
  appearance: none;
  background: var(--bgCta);
  border: none;
  border-radius: 100px;
  box-shadow: 0;
  color: #fff;
  font-size: 1rem;
  height: 3rem;
  padding: 0 1rem;
  width: 100%;
}
.calendarWrapper {
  position: relative;
}
.calendarButton {
  align-items: center;
  appearance: none;
  background: var(--bgCtaSecondary);
  border: none;
  border-radius: 100%;
  box-shadow: 0;
  color: var(--colorCtaSecondary);
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  height: 3rem;
  justify-content: center;
  padding: 0;
  width: 3rem;
}
.calendarIcon {
  height: auto;
  width: 1.5rem;
}
.calendar {
  display: none;
}
.activeCalendar {
  display: block;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}
