.questions {
  background: #fff;
}
.intro {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 85vh;
}
.introImage {
  background: #888;
  background-image: url("../images/intro_0.jpg");
  background-position: center;
  background-size: cover;
  border-radius: 100%;
  height: 100px;
  margin: 2rem auto;
  width: 100px;
}
.introShowcase {
  display: block;
  margin: 0 auto;
  max-width: 720px;
  width: 85%;
}
.firstImage {
  composes: introImage;
  background-image: url("../images/intro.jpg");
}
.secondImage {
  composes: introImage;
  background-image: url("../images/intro_lasagne.jpeg");
}
.thirdImage {
  composes: introImage;
  background-image: url("../images/intro_taco.jpeg");
}
.introHeading {
  font-size: 1.5rem;
  font-weight: 900;
  line-height: 1.2em;
  margin-bottom: 1rem;
  text-align: center;
}
.introText {
  line-height: 1.3em;
  text-align: center;
  margin-bottom: 1rem;
}
.introAction {
  padding-bottom: 2rem;
}
.introButton {
  appearance: none;
  background: var(--bgCta);
  border: none;
  border-radius: 100px;
  box-shadow: 0;
  color: #fff;
  display: block;
  font-size: 1rem;
  height: 3rem;
  margin: 0 auto;
  max-width: 250px;
  padding: 0 1rem;
  width: 100%;
}
.question {
  height: 100vh;
  padding: 0;
}
.questionHeader {
  background: #888;
  background-image: url("../images/hero.jpg");
  background-size: cover;
  height: 18vh;
}
.questionContent {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 1rem 1rem;
  min-height: 65vh;
  padding: 1.5rem;
  transform: translateY(-2rem);
}
.questionLabel {
  color: var(--red);
  display: block;
  margin-bottom: 1rem;
}
.questionHeading {
  font-size: 2rem;
  font-weight: 900;
  line-height: 1.2em;
  margin-bottom: 1rem;
}
.questionForm {
  margin-bottom: 1.5rem;
}
.questionButton {
  appearance: none;
  background: var(--bgCta);
  border: none;
  border-radius: 100px;
  box-shadow: 0;
  color: #fff;
  font-size: 1rem;
  height: 3rem;
  padding: 0 1rem;
  width: 100%;
}
