.slider {
  align-items: center;
  display: flex;
  position: relative;
  width: 100%;
}
.fill {
  background: currentColor;
  border-radius: 4px;
  height: 6px;
  position: absolute;
  z-index: 1;
}
.bgFill {
  background: var(--grayLight);
  border-radius: 4px;
  height: 6px;
  position: absolute;
  width: 100%;
}
.input {
  appearance: none;
  background: transparent;
  color: currentColor;
  margin: 0;
  width: 100%;
}
.labels {
  align-items: center;
  color: var(--gray);
  display: flex;
  justify-content: space-between;
  padding-top: 0.25rem;
  width: 100;
}
.input::-webkit-slider-thumb {
  appearance: none;
  background: currentColor;
  border-radius: 100%;
  cursor: pointer;
  height: 2rem;
  position: relative;
  width: 2rem;
  z-index: 2;
}
.input::-moz-range-thumb {
  background: currentColor;
  border-radius: 100%;
  cursor: pointer;
  height: 2rem;
  position: relative;
  transform: translateX(-15%);
  width: 2rem;
  z-index: 2;
}
