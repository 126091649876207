.image {
  background: #888;
  background-image: url('../images/intro.jpg');
  background-position: center;
  background-size: cover;
  border-radius: 100%;
  height: 250px;
  margin: 2rem auto;
  width: 250px;
}
.content {
  background-color: #fff;
  border-radius: 4px;
  margin: 0 1rem 2rem;
  padding: 1rem;
}
.contentHeading {
  font-weight: 700;
  line-height: 1.2em;
}
.contentDescription {
  line-height: 1.2em;
  margin-bottom: 1.5rem;
}
