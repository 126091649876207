.datePickerButton {
  align-items: center;
  appearance: none;
  background: #fff;
  border: 0;
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  color: var(--text);
  cursor: pointer;
  display: flex;
  font-size: 14px;
  padding: 0.75rem 1rem;
}
.calendarIcon {
  margin-left: 1rem;
  width: 1.25rem;
}
.datePicker {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  max-width: 375px;
  min-width: 375px;
  margin: 0.75rem 0 0;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 100;
}
.calendarHeading {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 1rem 0.5rem;
}
.monthYear {
  display: block;
  flex: 1;
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
}
.weekdays {
  border-bottom: 1px solid var(--graySpecial);
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 0.5rem;
}
.weekdayLabel {
  flex: 1;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  width: calc(100% / 7);
}
.monthSquares {
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem;
}
.emptySquare {
  background: transparent;
  width: calc(100% / 7);
}
.squareButton {
  appearance: none;
  background: #fff;
  border: 0;
  box-shadow: none;
  color: var(--text);
  cursor: pointer;
  height: 2.5rem;
  padding: 0;
  width: calc(100% / 7);
}
.squareButtonSelected {
  composes: squareButton;
  background: var(--bgCtaSecondary);
  color: var(--colorCtaSecondary);
  font-weight: 700;
}
.notSelectableButton {
  composes: squareButton;
  background: var(--graySpecial);
  border-radius: 0;
  color: var(--gray);
  cursor: default;
}
.prevButton,
.nextButton {
  appearance: none;
  background: transparent;
  border: 0;
  box-shadow: none;
  cursor: pointer;
  padding: 0;
}
.prevIcon,
.nextIcon {
  width: 1rem;
}
.hidden {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  width: 1px;
}
