.question {
  margin-bottom: 1.5rem;
}
.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.875rem;
}
.heading {
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.2em;
}
.answer {
  flex-shrink: 0;
  font-family: 'Ica Rubrik';
  font-size: 1rem;
  font-weight: 700;
  padding-left: 0.5rem;
}
