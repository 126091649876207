.subHeader {
  padding: 0.75rem 0;
}
.backButton {
  align-items: center;
  appearance: none;
  background: #fff;
  border: 0;
  border-radius: 100px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  color: var(--red);
  display: inline-flex;
  font-size: 14px;
  font-weight: 700;
  padding: 1rem 1.5rem 1rem 1.25rem;
  text-decoration: none;
}
.backIcon {
  margin-right: 0.25rem;
  width: 1.25rem;
}
.content {
  margin: 0 auto;
  max-width: 1200px;
  padding: 2rem 1rem;
  width: 100%;
}
.heading {
  color: var(--heading);
  font-size: 1.5rem;
  line-height: 1.4em;
  margin-bottom: 1rem;
}
.list {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 6%);
  counter-reset: custom-counter;
  list-style: none;
  padding: 0 0.5rem 0 1.5rem;
  position: relative;
}
.divider {
  align-items: center;
  background: var(--graySpecial);
  color: var(--gray);
  display: flex;
  justify-content: center;
  padding: 0rem 1rem;
  text-align: center;
}
.moreIcon {
  height: 2rem;
}
.extraTeamList {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 6%);
  counter-reset: custom-counter;
  list-style: none;
  padding: 0 0.5rem 0 1.5rem;
  position: relative;
}
.item {
  align-items: center;
  color: currentColor;
  counter-increment: custom-counter;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  text-decoration: none;
  padding: 1.25rem 1rem 1.25rem 1.25rem;
  position: relative;
}
.item:before {
  content: counter(custom-counter) ".";
  font-size: 12px;
  left: 0;
  position: absolute;
}
.position {
  font-size: 12px;
  font-weight: 300;
  left: 0;
  position: absolute;
}
.name {
  align-items: center;
  display: flex;
  font-weight: 700;
}
.isMyTeam {
  background: #ffe7f2;
  border-radius: 4px;
  color: #cf2e05;
  display: inline-block;
  font-size: 12px;
  margin-left: 1rem;
  padding: 2px 8px;
}
.extraItem {
  align-items: center;
  color: currentColor;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  text-decoration: none;
  padding: 1.25rem 1rem 1.25rem 1.25rem;
  position: relative;
}
